import { Col, Modal, Pagination, Row, Skeleton, Input, message, Button } from 'antd';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import _, { isEmpty, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid';
import { userCustomFieldsResource } from 'redux/resources/userCustomFields';
import UserCustomFieldEditor from './UserCustomFieldEditor';
import SRow from '../../../components/Standard/SRow';

const { Search } = Input;

const UserCustomFieldsManager = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const loading = useSelector(state => state.userCustomFieldsResource.loading);
  const customFields = useSelector(state => Object.values(state.userCustomFieldsResource.byIds));
  const integrations = useSelector(
    state => Object.values(state.integrationsResource.byIds),
    isEqual
  );
  const [editingCustomField, setEditingCustomField] = useState(null);
  const [addingCustomField, setAddingCustomField] = useState(null);
  const [search, setSearch] = useState('');

  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10
  });

  function loadUserCustomFieldsData(page, pageSize) {
    dispatch(
      userCustomFieldsResource.operations.load({
        organization_id: organizationId,
        page_number: page,
        page_size: pageSize,
        sort: '-created_at',
        search_input: search
      })
    ).then(resource => {
      setPagination({
        current: resource.meta.page,
        pageSize,
        total: resource.meta.totalPages
      });
    });
  }

  useEffect(() => {
    loadUserCustomFieldsData(1, 10);
  }, []);

  const onPaginationChange = (page, pageSize) => {
    loadUserCustomFieldsData(page, pageSize);
  };

  const onDeleteCustomField = customField => {
    if (customField.id === addingCustomField?.id) {
      return setAddingCustomField(null);
    }
    Modal.confirm({
      title: `${t(
        'integrationsSettingsPage.integrationPage.customFieldsSettings.deleteConfirm.title'
      )} ${customField.name}?`,
      okText: t(
        'integrationsSettingsPage.integrationPage.customFieldsSettings.deleteConfirm.okText'
      ),
      okType: 'danger',
      onOk: () => {
        dispatch(userCustomFieldsResource.operations.deleteById({ id: customField.id }));
        setAddingCustomField(null);
        setEditingCustomField(null);
      }
    });
  };

  const onSubmitCustomField = async customField => {
    if (customField.id === addingCustomField?.id) {
      // create custom field from state
      const resource = await dispatch(
        userCustomFieldsResource.operations.create({
          name: customField.name,
          field_key: customField.key,
          field_type: customField.fieldType,
          usage_for_ai: customField.usedForAi,
          usage_for_reviews: customField.usedForReviews,
          usage_for_filters: customField.usedForFilters,
          organization_id: organizationId
        })
      );
      // @todo обрабатывать ответ запроса и при превышении лимита выводить следующее модальное окно
      if (resource === undefined) {
        return Modal.confirm({
          title: 'Превышен лимит',
          okButtonProps: { style: { display: 'none' } },
          cancelButtonProps: { style: { display: 'none' } },
          content: (
            <div>
              <p className="ant-modal-confirm-content">
                Пользовательских полей в организации может быть не более 50. Пожалуйста, удалите или
                деактивируйте другие пользовательские поля, чтобы добавить новые!
              </p>
              <div
                className="ant-modal-confirm-btns"
                style={{ flexWrap: 'nowrap', display: 'flex', alignItems: 'center' }}
              >
                <Button type="primary" onClick={() => Modal.destroyAll()}>
                  Ок
                </Button>
              </div>
            </div>
          )
        });
      }
      if (resource) {
        setAddingCustomField(null);
      }

      return;
    }

    if (customField.id === editingCustomField?.id) {
      // update custom field props

      const result = await dispatch(
        userCustomFieldsResource.operations.updateById({
          id: customField.id,
          actions: {
            update_name: customField.name,
            update_key: customField.key,
            update_type: customField.fieldType,
            update_used_for_reviews: customField.usedForReviews,
            update_used_for_ai: customField.usedForAi,
            update_used_for_filters: customField.usedForFilters
          }
        })
      );

      if (result) {
        setEditingCustomField(null);
      }

      return;
    }

    setEditingCustomField(customField);
  };

  const onAddCustomField = () => {
    setAddingCustomField({
      id: uniqid(),
      fieldType: null,
      key: '',
      name: '',
      usedForReviews: true,
      usedForAi: true,
      usedForFilters: true
    });
  };

  return (
    <SCard bordered shadowed>
      <Row>
        <Col>
          <Search
            placeholder=""
            value={search}
            onChange={e => setSearch(e.target.value)}
            onSearch={() => loadUserCustomFieldsData(pagination.current, pagination.pageSize)}
            enterButton
            loading={loading}
          />
        </Col>
        <Col span={24}>
          <Row gutter={[0, 32]} style={{ marginBottom: '-16px', marginTop: '16px' }}>
            {loading ? (
              <Skeleton active />
            ) : (
              customFields.map(customField => (
                <UserCustomFieldEditor
                  key={customField.id}
                  customField={customField}
                  addingId={addingCustomField?.id}
                  editingId={editingCustomField?.id}
                  onDelete={onDeleteCustomField}
                  onSubmit={onSubmitCustomField}
                  integration={_.keyBy(integrations, 'id')[customField?.originIntegrationId]?.name}
                  setEditingCustomField={setEditingCustomField}
                />
              ))
            )}

            {!isEmpty(addingCustomField) && (
              <UserCustomFieldEditor
                customField={addingCustomField}
                addingId={addingCustomField?.id}
                editingId={editingCustomField?.id}
                onDelete={onDeleteCustomField}
                onSubmit={onSubmitCustomField}
                integration={
                  _.keyBy(integrations, 'id')[addingCustomField?.originIntegrationId]?.name
                }
                setEditingCustomField={setEditingCustomField}
              />
            )}

            <Col span={24}>
              <Row justify="end">
                <Col>
                  {isEmpty(addingCustomField) && isEmpty(editingCustomField) && (
                    <SButton onClick={onAddCustomField} type="primary" size="big" width="230px">
                      {t('integrationsSettingsPage.integrationPage.customFieldsSettings.addField')}
                    </SButton>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <SRow type="flex" justify="end" style={{ marginTop: '20px' }} gutter={[0, 16]}>
            <Pagination
              size="small"
              current={pagination.current}
              total={pagination.total * pagination.pageSize}
              pageSize={pagination.pageSize}
              onChange={onPaginationChange}
              showSizeChanger
              onShowSizeChange={onPaginationChange}
              pageSizeOptions={['10', '25', '50']}
            />
          </SRow>
        </Col>
      </Row>
    </SCard>
  );
};

export default UserCustomFieldsManager;
