import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { Col, Row, Skeleton, Typography } from 'antd';
import decamelizeKeys from 'decamelize-keys';
import { integrationsResource } from 'redux/resources/integrations';
import SCard from 'components/Standard/SCard';
import UserCustomFieldsManager from './UserCustomFieldsManager';

const { Title } = Typography;
const UserCustomFieldsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const integrations = useSelector(state => state.integrationsResource.byIds, isEqual);

  const loading = useSelector(
    state =>
      state.usersResource.loadByIdStarted ||
      state.usersResource.loading ||
      state.users.loading ||
      state.questionGroupsAnalytics.loading ||
      state.questionsAnalytics.loading ||
      state.integrationsResource.loading,
    isEqual
  );

  useEffect(() => {
    if (isEmpty(integrations)) {
      dispatch(integrationsResource.operations.load(decamelizeKeys({ id: organizationId })));
    }
  }, []);

  return (
    <Row style={{ margin: '16px' }}>
      <Col span={24}>
        <Row gutter={[0, 32]}>
          <Helmet>
            <title>{t('pagesMeta.userAnalyticsPage.title')}</title>
          </Helmet>
          <Col span={24}>
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <Title level={4}>
                  {t('integrationsSettingsPage.integrationPage.customFieldsSettings.title')}
                </Title>
              </Col>
              <Col span={24}>
                {loading ? (
                  <SCard>
                    <Skeleton active />
                  </SCard>
                ) : (
                  <UserCustomFieldsManager />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UserCustomFieldsPage;
