import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography, Select, Checkbox, Empty, Form, Divider } from 'antd';
import SCard from 'components/Standard/SCard';
import { useTranslation } from 'react-i18next';
import { isEmpty, isEqual } from 'lodash';
import { notificationSettingsResource } from 'redux/resources/notificationSettings';
import { NOTIFICATIONS_TYPES_LITERALS } from 'core/utils/constants';
import { setActiveRoleId } from 'redux/ui/notificationSettings/reducer';
import { connect, useDispatch, useSelector } from 'react-redux';
import SSelect from 'components/Standard/SSelect';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { useSelections } from 'ahooks';
import AiIcon from '../../../../../assets/images/ai-icon.svg';
import SText from '../../../../../components/Standard/SText';
import SButton from '../../../../../components/Standard/SButton';
import AddUsersModal from '../../../../../components/AddUsersModal';
import AddUsersAdminModal from '../../../../../components/AddUsersAdminModal';
import { selectSearch } from '../../../../../core/utils/selectSearch';
import { CustomOption } from '../../../../../components/Inputs/ChoiceSelect';
import { loadCustomFields } from '../../../../../redux/entities/customFields/operations';
import { customFieldsResource } from '../../../../../redux/resources/customFields';
import { statusesResource } from '../../../../../redux/resources/status';
import { rolesResource } from '../../../../../redux/resources/roles';
import { usersResource } from '../../../../../redux/resources/users';
import { unitsResource } from '../../../../../redux/resources/units';
import { operations as promptsListOperations } from '../../../../../redux/lists/promptsList';
import { setDefaultState } from '../../../../../redux/ui/clientInteractions/reducer';
import { checklistDefinitionsResource } from '../../../../../redux/resources/checklistDefinitions';
import { actions as actionsClientInteractionsList } from '../../../../../redux/lists/clientInteractionsList';
import CustomFieldValue from './CustomFieldValue';
import Icon from '../../../../../components/Icon';
import { Plus } from 'react-feather';
import { DeleteOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const { Option } = Select;

const updateNotification = notificationSettingsResource.operations.updateById;

const NotificationsCustomFieldsTg = ({ loadCustomFields }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeRoleId = useSelector(state => state.uiNotificationSettings.activeRoleId);

  const { byIds: rolesByIds = {}, loading } = useSelector(state => state.rolesResource, isEqual);

  const notifications = useSelector(
    state => Object.values(state.notificationSettingsResource.byIds),
    isEqual
  );

  const allowedNotifications = useMemo(
    () =>
      notifications.filter(
        item => item.eventType !== 'task_created' && item?.roleId === activeRoleId
      ),
    [notifications, activeRoleId]
  );
  const { ids } = useSelector(state => state.usersToUnitList, isEqual);
  const { allSelected, selected, isSelected, toggle, setSelected } = useSelections(ids, []);
  const [usersCount, setUserCount] = useState(0);
  const [showUsersModal, setShowUsersModal] = useState(false);

  const customFields = useSelector(state => state.customFieldsResource.byIds, isEqual);
  const promptsResource = useSelector(state => state.promptsResource.byIds);
  // Промпты с привязкой к кастомным полям
  const promptsWithCustomFields = Object.values(promptsResource).filter(
    prompt => prompt?.customFields?.length
  );
  // Идентификаторы кастомных полей
  const customFieldsIdsFromPromptsBindings = promptsWithCustomFields.flatMap(prompt =>
    (prompt.customFields || []).map(field => field.custom_field_id)
  );
  const customFieldsIdsSet = new Set(customFieldsIdsFromPromptsBindings);
  // Находим все сущности кастомных полей который привязаны к промпту по идентификаторам кастомноных полей
  const customFieldsWithBindings = Object.values(customFields).filter(customField =>
    customFieldsIdsSet.has(customField?.id)
  );
  const currentUserAttributes = useSelector(state => state.reduxTokenAuth.currentUser.attributes);
  const organizationId = currentUserAttributes?.user['organization-id'];
  const onAddUsers = usersIds => {
    setUserCount(usersIds.length);
    setShowUsersModal(false);
  };
  const [newCustomField, setNewCustomField] = useState(null);
  const [customFieldsArray, setCustomFieldsArray] = useState([]);
  useEffect(() => {
    if (isEmpty(customFields))
      loadCustomFields({
        organization_id: organizationId,
        pagination: false
      });
  }, []);
  const addNewCustomFields = () => {
    
  }
  return (
    <SCol sm={24} lg={24}>
      <SCard bordered loading={loading}>
        <SRow gutter={[0, 16]}>
          <SCol span={24}>
            <Title level={5}>
              Уведомления о событиях
              <img src={AiIcon} alt="ai-icon" style={{ marginLeft: '5px', paddingBottom: '3px' }} />
            </Title>
          </SCol>
          <SCol span={24} alignItems="center">
            <SRow type="flex" align="middle" justify="space-between">
              <SCol span={8}>
                <Text>Выбрать сотрудников</Text>
              </SCol>
              <SCol span={16}>
                <SCol
                  border={`1px solid var(${usersCount === 0 ? '--red_primary' : '--gray-border'})`}
                  borderRadius="4px"
                  height="40px"
                  style={{ padding: '0 4px 0 11px' }}
                >
                  <SRow
                    justify="space-between"
                    flexWrap="no-wrap"
                    align="middle"
                    gutter={[20, 0]}
                    height="100%"
                  >
                    <SCol style={{ paddingRight: 0 }}>
                      {`${t('general.selected')}: ${usersCount}`}
                    </SCol>
                    <SCol style={{ paddingLeft: 0 }}>
                      <SButton type="primary" onClick={() => setShowUsersModal(true)}>
                        {t('workPlanTaskConfigurationEditor.filters.add')}
                      </SButton>
                    </SCol>
                  </SRow>
                </SCol>
              </SCol>
            </SRow>
            <SRow type="flex" align="middle" justify="space-between" style={{ paddingTop: 16 }} gutter={[16,16]}>
              <SCol span={11}>
                <Select
                  placeholder={t('aiSettingsPage.taskSettings.customField')}
                  size="middle"
                  allowClear
                  showSearch
                  optionLabelProp="label"
                  filterOption={(input, option) =>
                    selectSearch({ input, option, searchProp: 'children' })
                  }
                  value={newCustomField}
                  onChange={item => setNewCustomField(item)}
                  style={{width: '100%'}}
                >
                  {Object.values(customFields).map(field => (
                    <Select.Option key={field.id} value={field.id} label={field.name}>
                      {field.name}
                    </Select.Option>
                  ))}
                </Select>
              </SCol>
              <SCol span={11}>
                {newCustomField && (
                  <CustomFieldValue
                    value={customFields[newCustomField]?.name}
                    fieldType={customFields[newCustomField]?.fieldType}
                    fieldName={customFields[newCustomField]?.fieldName}
                  />
                )}
              </SCol>
              <SCol span={2}>
                <SButton
                  type="link"
                  onClick={() => {}}
                  icon={<Icon style={{ color: 'red' }} icon={DeleteOutlined} />}
                />
              </SCol>
            </SRow>
            <SRow>
              <SCol>
                <SButton
                  type="link"
                  padding="0"
                  icon={<Icon icon={Plus} />}
                >
                  Добавить кастомное поле
                </SButton>
              </SCol>
            </SRow>
          </SCol>
          <SCol span={24} />
        </SRow>
      </SCard>
      <AddUsersAdminModal
        visible={showUsersModal}
        onCancel={() => setShowUsersModal(false)}
        onAdd={onAddUsers}
        title="Сотрудники"
        selectedUsersIds={[]}
        allSelected={allSelected}
        selected={selected}
        isSelected={isSelected}
        toggle={toggle}
        setSelected={setSelected}
      />
    </SCol>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { filters: filterClientInteractions } = state.clientInteractionsList;
  const { customFieldFilters } = state.clientInteractionsList;
  const { byIds: salePipelinesByIds } = state.salePipelinesResource;
  return {
    filters: ownProps?.hasConflicts ? {} : filterClientInteractions,
    customFields: state.customFieldsResource.byIds,
    filterClientInteractions,
    customFieldFilters,
    salePipelines: Object.values(salePipelinesByIds)
  };
};

const mapDispatchToProps = {
  loadCustomFields: customFieldsResource.operations.load,
  loadStatuses: statusesResource.operations.load,
  loadRoles: rolesResource.operations.load,
  loadUsers: usersResource.operations.load,
  loadUnits: unitsResource.operations.loadUnits,
  loadPrompts: promptsListOperations.load,
  setDefaultState,
  loadChecklistDefinitions: checklistDefinitionsResource.operations.loadWithInclude,
  clearFilters: actionsClientInteractionsList.clearFilters,
  updateFilters: actionsClientInteractionsList.updateFilters,
  updateCustomFieldFilters: actionsClientInteractionsList.updateCustomFieldFilters,
  setCustomFieldFilters: actionsClientInteractionsList.setCustomFieldFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsCustomFieldsTg);
