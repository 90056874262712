import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { CUSTOM_FIELDS_TYPES, DATE_PICKER_MODES } from '../core/utils/constants';
import DurationPicker from './Inputs/DurationPicker';
import RangeInputs from './Inputs/RangeInputs';
import CustomDatePicker from './DatePicker/DatePicker';
import { StyledSelect } from './WidgetUserFields';
import FilterName from '../pages/ClientInteractionsPage/components/FilterName';

const CustomFieldValue = ({ fieldName, fieldType, disabled, value, onChange }) => {
  const { t } = useTranslation();

  switch (fieldType) {
    case CUSTOM_FIELDS_TYPES.DURATION:
      return (
        <>
          <FilterName>{fieldName}</FilterName>
          <DurationPicker
            disabled={disabled}
            value={value}
            onChange={onChange}
            style={{ width: '100%' }}
            allowClear
          />
        </>
      );

    case CUSTOM_FIELDS_TYPES.BOOLEAN:
      return (
        <>
          <FilterName>{fieldName}</FilterName>
          <StyledSelect
            disabled={disabled}
            value={value}
            onChange={onChange}
            showSearch
            allowClear
            placeholder={fieldName}
          >
            {[
              { label: t('general.yes'), value: 'true' },
              { label: t('general.no'), value: 'false' }
            ].map(({ label, value }) => (
              <StyledSelect.Option key={value} value={value} label={label}>
                {label}
              </StyledSelect.Option>
            ))}
          </StyledSelect>
        </>
      );

    case CUSTOM_FIELDS_TYPES.STRING:
    case CUSTOM_FIELDS_TYPES.STRING_ARRAY:
      return (
        <>
          <FilterName>{fieldName}</FilterName>
          <Input disabled={disabled} value={value} onChange={onChange} placeholder={fieldName} />
        </>
      );

    case CUSTOM_FIELDS_TYPES.NUMBER:
    case CUSTOM_FIELDS_TYPES.NUMBER_ARRAY:
      return (
        <>
          <FilterName>{fieldName}</FilterName>
          <RangeInputs disabled={disabled} value={value} onChange={onChange} precision={0} />
        </>
      );

    case CUSTOM_FIELDS_TYPES.DATETIME:
      return (
        <>
          <FilterName>{fieldName}</FilterName>
          <CustomDatePicker
            mode={DATE_PICKER_MODES.custom.customFields}
            isClientInteractionPage
            allowClear
            disabled={disabled}
            value={value}
            onChange={onChange}
          />
        </>
      );

    default:
      return <div />;
  }
};

export default CustomFieldValue;
