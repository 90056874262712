import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { getTranscriptionWithParts } from 'redux/selectors/phoneCallTranscriptions';
import { Button, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ConversationPart from './TextCommunicationContent/components/ConversationPart';

const TranscriptionParts = ({ phoneCallTranscriptionId, reviewId, startedAt, operatorId }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const transcription = useSelector(
    state => getTranscriptionWithParts(state, phoneCallTranscriptionId),
    isEqual
  );

  const refs = transcription?.parts.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  const handleExport = () => {
    if (!transcription) return;

    const { parts } = transcription;

    const lines = parts.map(part => {
      const { text, startTime, role } = part;
      const time = new Date(startedAt);
      time.setSeconds(time.getSeconds() + startTime);
      const formattedTime = time.toLocaleString();
      return `[${formattedTime}] ${role.toUpperCase()}: ${text}`;
    });

    const fileContent = lines.join('\n');
    const blob = new Blob([fileContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `transcription_${reviewId}.txt`;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  if (isEmpty(transcription)) {
    return '';
  }

  return (
    <>
      <a onClick={() => handleOpenModal()}>{t('aiSettingsPage.taskStatus.transcription')}</a>
      {visible && (
        <Modal
          title={t('components.recordPlayer.transcription.tooltip')}
          width={800}
          visible={visible}
          onCancel={() => handleCloseModal()}
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button type="primary" onClick={() => handleExport()}>
                {t('clientInteractionsPage.drawer.tables.menu.exportToTxt')}
              </Button>
              <Button type="default" onClick={() => handleCloseModal()}>
                {t('general.closeText')}
              </Button>
            </div>
          }
        >
          <div style={{ maxHeight: '500px', overflowY: 'auto', overflowX: 'hidden' }}>
            <Row gutter={[16, 16]}>
              {transcription?.parts
                .sort((a, b) => a?.startTime - b?.startTime)
                .map(message => (
                  <ConversationPart
                    key={message.id}
                    id={message.id}
                    postedAt={moment(startedAt).add(message.startTime, 'seconds')}
                    seconds={message.startTime}
                    operatorId={message.role === 'operator' ? operatorId : undefined}
                    body={message.text}
                    ref={refs[message.id]}
                    textCommunicationStartedAt={startedAt}
                    fromClientInteractions
                  />
                ))}
            </Row>
          </div>
        </Modal>
      )}
    </>
  );
};

export default TranscriptionParts;
