import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  byKey: {},
  loading: false
};

export const setLoading = createAction('customFields/setLoading');
export const customFieldsLoaded = createAction('customFields/customFieldsLoaded');

export const libraryReducer = createReducer(defaultState, {
  [setLoading]: (state, { payload }) => ({ ...state, loading: payload }),
  [customFieldsLoaded]: (state, { payload }) => ({ ...state, loading: false, byKey: payload })
});
