import { Button, Tooltip, Typography } from 'antd';
import ClientInteractionLink from 'components/ClientInteractionLink';
import CommentsPreview from 'components/Comments/CommentsPreview';
import CustomFieldValueView from 'components/CustomFieldValueView';
import StatusSelector from 'components/PhoneCallPlayer/RecordPlayer/StatusSelector';
import { TagsDisplay } from 'components/Tags/TagsDisplay';
import UserPreview from 'components/UserPreview';
import addScopeToObjectKeys from 'core/utils/addScopeToObject';
import { CALL_DIRECTION_LITERALS } from 'core/utils/constants';
import { beatifyFloat } from 'core/utils/convertFloat';
import { reduce, compact, get, isEqual, filter, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { utils } from 'react-media-player';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { BookOutlined } from '@ant-design/icons';
import { DeactivatedUserLinkToCommunication } from './DeactivatedLinkToCommunication';
import SText from '../../../components/Standard/SText';
import TranscriptionParts from '../../../components/TranscriptionParts';
// import IconButton from '../../../components/IconButton';

const IconButton = styled(Button)`
  font-size: 16px;
  border: none !important;
  margin: ${props => props.margin};
  box-shadow: none !important;
  background-color: transparent;

  &:hover {
    background-color: transparent !important;
    color: inherit !important;
  }

  svg {
    color: inherit !important;
  }
`;

const { Text } = Typography;
export const isJsonStr = string => {
  try {
    JSON.parse(string);
    return true;
  } catch (e) {
    return false;
  }
};
export const renderCustomField = ({ key, customFieldsByKeys }) => (
  data,
  record,
  wrapValue = true
) => {
  let parsedCustomFields = null;
  if (typeof record?.customFields === 'object') {
    parsedCustomFields = record?.customFields;
  }
  if (isJsonStr(record.customFields)) {
    parsedCustomFields = JSON.parse(record.customFields);
  }

  // const parsedCustomFields =
  //   typeof record?.customFields === 'object'
  //     ? record?.customFields
  //     : isJsonStr(record.customFields)
  //     ? JSON.parse(record.customFields)
  //     : null;

  return (
    <Tooltip title={data}>
      <SText ellipsis maxWidth={200}>
        {CustomFieldValueView({
          customFields: addScopeToObjectKeys('customField', parsedCustomFields),
          customFieldsByKeys,
          customFieldKey: key,
          value: data,
          wrapValue
        })}
      </SText>
    </Tooltip>
  );
};

const ColumnsRender = ({
  onRowExpand,
  customFieldsByKeys,
  questionsChecklist,
  col,
  text,
  record
}) => {
  const { t } = useTranslation();
  const crmEntitiesByIds = useSelector(state => state.crmEntitiesResource.byIds);
  const commentsReview = useSelector(state => state.commentsResource.byIds, isEqual);
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
  const renderColumnData = useMemo(
    () => ({
      communicationType: (
        type,
        { reviewId, operator, id, communicationType, clientInteractionType, reviewLibraries }
      ) => {
        return operator?.active ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '0 5px' }}>
            <ClientInteractionLink
              communication={{ communicationType, clientInteractionType }}
              isReview={!!reviewId}
              id={reviewId || id}
              style={{ width: '24px', height: '24px', display: 'block' }}
            />
            {!isEmpty(reviewLibraries) && (
              <Tooltip title={t('constants.plansDescription.libraryAdded')}>
                <IconButton shape="circle-outline" icon={<BookOutlined />} size="small" />
              </Tooltip>
            )}
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', gap: '0 5px' }}>
            <DeactivatedUserLinkToCommunication>
              <ClientInteractionLink
                style={{
                  width: '24px',
                  height: '24px',
                  display: 'block',
                  color: 'var(--blue_2)'
                }}
                communication={{ communicationType, clientInteractionType }}
                isReview={!!reviewId}
                id={reviewId || id}
              />
            </DeactivatedUserLinkToCommunication>
            {!isEmpty(reviewLibraries) && (
              <Tooltip title={t('constants.plansDescription.libraryAdded')}>
                <IconButton shape="circle-outline" icon={<BookOutlined />} size="small" />
              </Tooltip>
            )}
          </div>
        );
      },
      // Дата обновления
      updatedAt: (data, record) => {
        const getUpdatedAt = () => {
          // Если коммуникация оценена
          if (record.reviewUpdatedAt) {
            return record.reviewUpdatedAt;
          }
          // Если коммуникация без оценки
          return record?.updatedAt;
        };
        const updatedAt = getUpdatedAt();
        return (
          <CenteredData>
            <Text strong style={{ paddingRight: '4px' }}>
              {`${moment(updatedAt).format('DD/MM/YYYY, HH:mm')}`}
            </Text>
          </CenteredData>
        );
      },
      // Дата создания
      createdAt: (data, record) => {
        let createdAt;
        if (Object.keys(record).includes('type') && record.type === 'client-interactions') {
          createdAt = record.createdAt;
        } else {
          createdAt = record.reviewCreatedAt;
        }
        return (
          <CenteredData>
            <Text strong style={{ paddingRight: '4px' }}>
              {`${moment(createdAt).format('DD/MM/YYYY, HH:mm')}`}
            </Text>
          </CenteredData>
        );
      },
      // Дата и время
      startedAt: data =>
        data && (
          <CenteredData>
            <Text strong style={{ paddingRight: '4px' }}>
              {`${moment(data).format('DD/MM/YYYY, HH:mm')}`}
            </Text>
          </CenteredData>
        ),
      duration: data =>
        data ? (
          <CenteredData>
            <Text strong>{utils.formatTime(data)}</Text>
          </CenteredData>
        ) : (
          ''
        ),
      direction: data => t(CALL_DIRECTION_LITERALS[data]),
      operatorId: (data, record) => {
        const userId = data || record?.reviewOperatorId;
        return userId ? <UserPreview userId={userId} disabled showAvatar margin="0px" /> : '';
      },
      isReviewed: (data, record) =>
        record.clientInteractionType &&
        (record.reviewId
          ? t('constants.reviewStatuses.reviewed')
          : t('constants.reviewStatuses.notReviewed')),
      reviewsCount: (data, record) => {
        const reviews = record?.reviews;

        if (reviews?.length === 1) {
          return (
            <CenteredLink to={`/reviews/${reviews[0].reviewId}`}>{reviews.length}</CenteredLink>
          );
        }

        return reviews?.length > 1 ? (
          <CenteredButton type="link" onClick={() => onRowExpand(record)}>
            {reviews.length}
          </CenteredButton>
        ) : null;
      },
      clientId: (data, record) => record?.client?.integrationUid || null,
      unitId: (data, record) => record?.unit?.name || null,
      nps: data => <CenteredData>{data}</CenteredData>,
      email: (data, record) => record?.email,
      clientPhoneNumber: data => {
        const maxLength = 15;
        return (
          <Tooltip title={data}>
            <SText ellipsis>
              {data?.length > maxLength ? `${data.substring(0, maxLength)}...` : data}
            </SText>
          </Tooltip>
        );
      },
      partsCount: (data, record) => (
        <CenteredData>
          {record.textCommunicationPartsCount || record.textCommunicationPartsIds?.length || ''}
        </CenteredData>
      ),
      score: (data, record) => (
        <CenteredData>{beatifyFloat(record?.reviewChecklist?.score ?? data)}</CenteredData>
      ),
      chatId: (data, record) => record.communicationId || null,
      ticketId: (data, record) => record.communicationId || null,
      reviewReviewerId: data =>
        data ? <UserPreview disabled userId={data} showAvatar margin="0px" hidden /> : null,
      reviewCreatedAt: data =>
        data && (
          <CenteredData>
            <Text strong style={{ paddingRight: '4px' }}>
              {`${moment(data).format('DD/MM/YYYY, HH:mm')}`}
            </Text>
          </CenteredData>
        ),
      checklistDefinitionName: (data, record) => record?.reviewChecklistDefinition?.name || null,
      reviewComments: (data, record) => {
        const comments = data || [];
        const filteredComments = comments.filter(comment => {
          if (isEmpty(comment?.checklistDefinitionId) || comment?.checklistDefinitionId === null) {
            return comment;
          }
          return comment?.checklistDefinitionId === record?.reviewChecklistDefinition?.id;
        });
        return (
          <CommentsPreview
            comments={filteredComments || []}
            checklistData={{ checklist: record.reviewChecklist }}
          />
        );
      },
      reviewTasksCount: (data, record) => {
        const tasksCount = record?.tasks?.length || 0;

        return tasksCount > 1 ? (
          <CenteredButton type="link" onClick={() => onRowExpand(record)}>
            {tasksCount}
          </CenteredButton>
        ) : null;
      },
      taskTaskDefinitions: (data, record) => {
        const recordTasks = record?.tasks ? Object.values(record?.tasks) : [];
        const filteredTasks = record?.reviewTasksIds
          ? recordTasks.filter(
              item => record?.reviewTasksIds && record?.reviewTasksIds.includes(item.id)
            )
          : null;
        const renderTask = () => {
          if (filteredTasks) {
            return <TagsDisplay tasks={filteredTasks} truncate={16} />;
          }
          if (recordTasks) {
            return <TagsDisplay tasks={recordTasks} truncate={16} />;
          }
          return '';
        };
        return renderTask();
      },
      status: (data, record) =>
        (record?.type === 'client-interactions' || record?.type === 'client_interactions') && (
          <StatusSelector canAddStatus={false} allowLoadStatuses={false} call={record} />
        ),
      crmEntityId: (data, record) => {
        const crmEntity = get(crmEntitiesByIds, record.crmEntityId, {});
        const linkText = compact([crmEntity?.integrationUid, crmEntity?.title]).join(' ');
        return (
          <Link target="_blank" type="link" to={`crm-entity/${crmEntity?.id}`}>
            {`${linkText ? '#' : ''}${linkText}`}
          </Link>
        );
      },
      transcriptionId: (data, record) => {
        const phoneCallTranscriptionId = record?.phoneCallTranscriptionId;
        return (
          <TranscriptionParts
            phoneCallTranscriptionId={phoneCallTranscriptionId}
            reviewId={record?.id}
            startedAt={record?.startedAt}
            operatorId={record?.operatorId}
          />
        );
      },
      ...reduce(
        customFieldsByKeys,
        (acc, curr, key) => ({ ...acc, [key]: renderCustomField({ key, customFieldsByKeys }) }),
        {}
      )
    }),
    [onRowExpand, customFieldsByKeys, questionsChecklist]
  );
  if (record.clientInteractionType && record.reviewId) {
    if (regex.test(col)) {
      const matchingComments = filter(commentsReview, comment => {
        const metadata = isJsonStr(comment?.metadata)
          ? JSON.parse(comment?.metadata)
          : comment?.metadata;

        return (
          (metadata?.question_id === col || metadata?.questionId === col) &&
          comment?.commentableId === record.reviewId
        );
      });

      return <CommentsPreview comments={matchingComments} />;
    }
  }

  return renderColumnData[col] && renderColumnData[col](text, record)
    ? renderColumnData[col](text, record)
    : null;
};

export default ColumnsRender;

const CenteredButton = styled(Button)`
  width: 100%;
  align-self: center;
`;

const CenteredLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredData = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
`;
