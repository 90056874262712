import { Col, Select, Row, Spin, Typography, List, Checkbox } from 'antd';
import { actions, operations } from 'redux/lists/usersToUnitList';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserPreview from 'components/UserPreview';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual, throttle, isEmpty, uniq, intersection } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getUnitsByIds } from 'redux/selectors/units';
import { getRolesByIds } from 'redux/selectors/roles';
import Search from 'components/Inputs/Search';
import SModal from 'components/Standard/SModal';
import SSelect from 'components/Standard/SSelect';
import { selectSearch } from 'core/utils/selectSearch';
import { SListItem } from 'components/Standard/SList';
import { WidgetTitle } from 'components/WidgetTitle';
import SRow from './Standard/SRow';
import SCol from './Standard/SCol';
import CheckboxModal from '../pages/WorkPlanTaskConfigurationEditor/ConfigurationEditorModules/CheckboxModal';
import { addUsersByUnit } from '../redux/entities/organization/operations';

const { Text } = Typography;
const { Option } = Select;

const AddUsersAdminModal = ({
  title,
  onAdd,
  onCancel,
  selectedUsersIds = [],
  visible,
  addedFilters = {},
  disabledUsersIds = [],
  columns = ['employee', 'unit', 'role'],
  okText,
  showDisableReason,
  disableReasonComponent,
  rolesDisplayPermissions,
  allSelected,
  selected,
  isSelected,
  toggle,
  setSelected,
  addReviewer
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showCheckboxModal, setShowCheckboxModal] = useState(false);
  // Данный флаг нужен для того чтобы при выборе для текущей страницы автоматически выделять все галочки на других страницах пагинации
  const [checkboxModalAction, setCheckboxModalAction] = useState(null);

  const unitsByIds = useSelector(getUnitsByIds, isEqual);
  const rolesByIds = useSelector(getRolesByIds, isEqual);
  const loading = useSelector(state => state.usersToUnitList.loading);
  const { totalPages, page, filters, search, ids } = useSelector(
    state => state.usersToUnitList,
    isEqual
  );
  // TODO fix useeffect to not do uniq
  const users = useSelector(
    state => uniq(ids || []).map(id => state.usersResource.byIds[id]),
    isEqual
  );

  const roles = isEmpty(rolesDisplayPermissions)
    ? Object.values(rolesByIds)
    : Object.values(rolesByIds).filter(
        role => intersection(role.permissions, rolesDisplayPermissions).length !== 0
      );

  const hasNextPage = totalPages > page.number;
  const isEmptySelected = selected?.length === 0;

  const resetState = () => {
    setShowCheckboxModal(false);
    setCheckboxModalAction(null);
  };

  const closeCheckboxModal = () => {
    setShowCheckboxModal(false);
  };

  const showModalCheckboxModal = () => {
    setShowCheckboxModal(true);
  };

  const handleSelectCurrentPage = () => {
    const uniqIds = ids.filter((value, index) => ids.indexOf(value) === index);
    // убираем тех у кого нет прав доступа к записям одного или нескольких операторов
    const withoutDisabledUsers = uniqIds.filter(id => !disabledUsersIds.includes(id));
    setSelected(withoutDisabledUsers);
    setCheckboxModalAction('currentPage');
    closeCheckboxModal();
  };

  const handleUnselectAll = () => {
    setSelected([]);
  };

  const handleSelectActiveFilter = () => {
    dispatch(addUsersByUnit(filters, search, setSelected, t));
    setCheckboxModalAction('activeFilter');
    closeCheckboxModal();
  };

  const columnNameToComponent = {
    employee: (
      <SCol span={10} key="employee">
        <SRow>
          <SCol width="24px" />
          <SCol padding="6px 0">
            {!addReviewer && (
              <Checkbox
                checked={!isEmptySelected}
                onChange={e => {
                  e.stopPropagation();
                  if (!isEmptySelected) {
                    resetState();
                    handleUnselectAll();
                  } else {
                    showModalCheckboxModal();
                  }
                }}
              />
            )}
            <Text style={{ marginLeft: '8px' }} strong>
              {t('workPlanTaskConfigurationEditor.addUsersModal.users')}
            </Text>
          </SCol>
        </SRow>
      </SCol>
    )
  };

  const onSave = () => {
    onAdd(selected);
    resetState();
  };

  const onCloseModal = () => {
    onCancel();
    resetState();
  };

  const onSearch = search => {
    dispatch(actions.clearList());
    dispatch(actions.setSearch(search));
    dispatch(
      operations.load({
        search,
        page: { ...page, number: 1 },
        filters: { ...filters, ...addedFilters },
        sort: 'last_name',
        include: 'unit,role.levels'
      })
    );
  };

  useEffect(() => {
    if (visible) {
      setSelected(selectedUsersIds);
      dispatch(
        operations.load({
          search,
          page: { ...page, number: 1 },
          filters: { ...filters, ...addedFilters },
          sort: 'last_name',
          include: 'unit,role.levels'
        })
      );
    } else {
      dispatch(actions.setSearch(null));
      dispatch(actions.clearList());
      dispatch(actions.setFilters({}));
    }

    return () => {
      dispatch(actions.setSearch(null));
      dispatch(actions.clearList());
      dispatch(actions.setFilters({}));
    };
  }, [visible]);

  useEffect(() => {
    dispatch(actions.clearList());
    if (visible)
      dispatch(
        operations.load({
          search,
          page: { ...page, number: 1 },
          filters: { ...filters, ...addedFilters },
          sort: 'last_name',
          include: 'unit,role.levels'
        })
      );
  }, [filters.unitsIds?.length, filters.rolesIds?.length]);

  useEffect(() => {
    if (checkboxModalAction === 'currentPage') {
      handleSelectCurrentPage();
    }
  }, [ids, filters, checkboxModalAction, setCheckboxModalAction]);

  const renderUser = user => {
    return (
      <SListItem key={user.id} padding="0" _hover="cursor:pointer">
        {columns.includes('employee') && (
          <Col span={10}>
            <Row gutter={[8, 0]} align="middle">
              <SCol padding="6px 0">
                <Checkbox
                  disabled={disabledUsersIds.includes(user.id)}
                  checked={isSelected(user.id)}
                  onChange={e => {
                    e.stopPropagation();
                    toggle(user?.id);
                  }}
                />
              </SCol>
              <SCol padding="6px 0" maxWidth="calc(100% - 24px)">
                <SRow gutter={[8, 0]}>
                  <SCol>
                    <UserPreview showAvatar disabled user={user} />
                  </SCol>
                  {showDisableReason && disabledUsersIds.includes(user?.id) && (
                    <SCol display="flex" alignItems="center">
                      {disableReasonComponent}
                    </SCol>
                  )}
                </SRow>
              </SCol>
            </Row>
          </Col>
        )}
      </SListItem>
    );
  };

  const onScrollY = throttle(async el => {
    const isOnBottom = (element => {
      return element.clientHeight + element.scrollTop + 20 >= element.scrollHeight;
    })(el);

    if (hasNextPage && !loading && isOnBottom) {
      const newPage = `${parseInt(page.number) + 1}`;
      dispatch(actions.updatePage({ number: newPage }));
      dispatch(
        operations.load({
          search,
          page: { ...page, number: newPage },
          filters: { ...filters, ...addedFilters },
          sort: 'last_name',
          include: 'unit,role.levels'
        })
      );
    }
  }, 250);

  return (
    <SModal
      width="950px"
      size="big"
      destroyOnClose
      title={
        <Row align="middle" justify="space-between" style={{ paddingRight: '25px' }}>
          <Col span={6}>
            <WidgetTitle>{isEmpty(title) ? t('components.addUsersModal') : title}</WidgetTitle>
          </Col>

          <Col span={18}>
            <Search
              loading={loading}
              placeholder={t('organizationStructure.tabs.employees.table.filters.employeesSearch')}
              onSearch={onSearch}
            />
          </Col>
        </Row>
      }
      visible={visible}
      onOk={onSave}
      onCancel={onCloseModal}
      okText={okText || t('general.save')}
      cancelText={<></>}
    >
      <List.Item style={{ borderBottom: '1px solid var(--gray_4)', padding: '0' }}>
        {columns.map(columnName => columnNameToComponent[columnName])}
      </List.Item>
      <PerfectScrollbar
        onScrollY={onScrollY}
        options={{ suppressScrollX: true }}
        style={{ maxHeight: '385px' }}
      >
        <List dataSource={users} renderItem={renderUser} rowKey="id" />
        <Spin spinning={loading}>
          <List.Item />
        </Spin>
      </PerfectScrollbar>
      <CheckboxModal
        showCheckboxModal={showCheckboxModal}
        closeCheckboxModal={closeCheckboxModal}
        setCheckboxModalAction={setCheckboxModalAction}
        handleSelectCurrentPage={handleSelectCurrentPage}
        handleSelectActiveFilter={handleSelectActiveFilter}
      />
    </SModal>
  );
};

export default AddUsersAdminModal;
