import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setActiveRoleId } from 'redux/ui/notificationSettings/reducer';
import { rolesResource } from 'redux/resources/roles';
import { get, isEqual } from 'lodash';
import { notificationSettingsResource } from 'redux/resources/notificationSettings';
import { statusesResource } from 'redux/resources/status';
import { getCurrentUser } from 'redux/selectors/users';
import { setStatusesByIds } from 'redux/ui/statusesPage/reducer';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import SRow from 'components/Standard/SRow';
import Notifications from './Notifications/Notifications';
import Statuses from './Statuses/Statuses';
import { Col, Row } from 'antd';
import SCol from '../../../../components/Standard/SCol';
import NotificationsCustomFieldsTg from './NotificationsCustomFieldsTG/NotificationsCustomFieldsTg';

const loadRoles = rolesResource.operations.load;
const loadNotifications = notificationSettingsResource.operations.load;
const loadStatuses = statusesResource.operations.load;

const NotificationsAndStatuses = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentUserRoleId = useSelector(state => get(getCurrentUser(state), 'role.id', ''));
  const { byIds: statusesByIds } = useSelector(state => state.statusesResource, shallowEqual);
  const organizationInfo = useSelector(state => state.organizationInfo.organization, isEqual);
  const canAiAnalysisClientInteractionsInOrganization = get(
    organizationInfo,
    'settings.ai.analysis.enabled',
    false
  );
  useEffect(() => {
    dispatch(setActiveRoleId({ id: currentUserRoleId }));
    dispatch(loadRoles());
    dispatch(loadNotifications());
    dispatch(loadStatuses());
  }, []);

  useEffect(() => {
    dispatch(setStatusesByIds(statusesByIds));
  }, [Object.values(statusesByIds).length]);

  return (
    <SRow gutter={[16, 16]}>
      <Helmet>
        <title>
          {t('pagesMeta.organizationStructurePage.tabs.notificationsAndStatuses.title')}
        </title>
      </Helmet>
      <SCol span={12}>
        <Notifications />
        {canAiAnalysisClientInteractionsInOrganization && <NotificationsCustomFieldsTg />}
      </SCol>
      <SCol span={12}>
        <Statuses />
      </SCol>
    </SRow>
  );
};

export default NotificationsAndStatuses;
