import React from 'react';
import { CUSTOM_FIELDS_TYPES, DATE_PICKER_MODES } from '../../../../../core/utils/constants';
import FilterName from '../../../../ClientInteractionsPage/components/FilterName';
import DurationPicker from '../../../../../components/Inputs/DurationPicker';
import { isEmpty, isNil } from 'lodash';
import { StyledSelect } from '../../../../ClientInteractionsPage/components/styled';
import { selectSearch } from '../../../../../core/utils/selectSearch';
import { useTranslation } from 'react-i18next';
import { Input, Select } from 'antd';
import RangeInputs from '../../../../../components/Inputs/RangeInputs';
import CustomDatePicker from '../../../../../components/DatePicker/DatePicker';
const { Option } = Select;
const CustomFieldValue = ({
  fieldName,
  fieldType, disabled, value
                          }) => {
const {t} = useTranslation();
  if (fieldType === CUSTOM_FIELDS_TYPES.DURATION) {
    return (
      <>
        <DurationPicker
          from={value?.from}
          to={value?.to}
          disabled={disabled}
          size="medium"
          style={{ width: '100%' }}
          allowClear
          // onChange={({ from, to }) => {
          //   updateCustomFieldFilters({
          //     [key]: {
          //       from: isNil(from) ? undefined : `${from}`,
          //       to: isNil(to) ? undefined : `${to}`
          //     }
          //   });
          // }}
        />
      </>
    );
  }

  if (fieldType === CUSTOM_FIELDS_TYPES.BOOLEAN) {
    return (
      <>
        <StyledSelect
          disabled={disabled}
          showSearch
          allowClear
          optionLabelProp="label"
          placeholder={fieldName}
          // value={customFieldFilters[key]?.eq || undefined}
          // onChange={value => updateCustomFieldFilters({ [key]: { eq: value } })}
          filterOption={(input, option) =>
            selectSearch({ input, option, searchProp: 'children' })
          }
        >
          {[
            { label: t('general.yes'), value: 'true' },
            { label: t('general.no'), value: 'false' }
          ].map(({ label, value }) => (
            <Option key={label} value={value} label={label}>
              {label}
            </Option>
          ))}
        </StyledSelect>
      </>
    );
  }

  if (
    fieldType === CUSTOM_FIELDS_TYPES.STRING ||
    (fieldType === CUSTOM_FIELDS_TYPES.STRING_ARRAY)
  ) {
    return (
      <Input
        disabled={disabled}
        // value={customFieldFilters[key]?.contains || undefined}
        // onChange={e => updateCustomFieldFilters({ [key]: { contains: e.target.value } })}
        placeholder={fieldName}
      />
    );
  }

  if (
    fieldType === CUSTOM_FIELDS_TYPES.NUMBER ||
    fieldType === CUSTOM_FIELDS_TYPES.NUMBER_ARRAY
  ) {
    return (
      <>
        <RangeInputs
          disabled={disabled}
          // value={customFieldFilters[key]}
          precision={0}
          // onChange={({ from, to }) => updateCustomFieldFilters({ [key]: { from, to } })}
        />
      </>
    );
  }
  if (fieldType === CUSTOM_FIELDS_TYPES.DATETIME) {
    return (
      <>
        <CustomDatePicker
          // onChange={value => updateCustomFieldFilters({ [key]: value })}
          // value={customFieldFilters[key] || {}}
          mode={DATE_PICKER_MODES.custom.customFields}
          isClientInteractionPage
          allowClear
          loading={disabled}
          disabled={disabled}
        />
      </>
    );
  }

  return (
    <div>

    </div>
  );
};

export default CustomFieldValue;
