export function isDashboardInLocalStorage(id) {
  try {
    return localStorage.getItem(id) !== null;
  } catch (e) {
    console.error('Ошибка при проверке в localStorage:', e);
    return false;
  }
}

export function getDashboardInLocalStorage(id) {
  try {
    const data = localStorage.getItem(id);
    return data ? JSON.parse(data) : null;
  } catch (e) {
    console.error('Ошибка при получении из localStorage:', e);
    return null;
  }
}

export function getDashboardsInLocalStorage(ids) {
  try {
    const result = {};
    let count = ids.length;

    ids.forEach(id => {
      const dashboard = getDashboardInLocalStorage(id);
      if (dashboard === null) {
        count--;
        return null;
      }
      result[id] = dashboard;
    });

    return count === ids.length ? result : null;
  } catch (e) {
    console.error('Ошибка при получении из localStorage:', e);
    return null;
  }
}

export function deleteDashboardInLocalStorage(id) {
  try {
    localStorage.removeItem(id);
  } catch (e) {
    console.error('Ошибка при удалении из localStorage:', e);
  }
}

export function setDashboardInLocalStorage(id, data) {
  try {
    const value = data[id];
    let updatedData;

    if (typeof value !== 'object' || value === null) {
      updatedData = {
        value,
        dateLastUpdate: new Date().toISOString()
      };
    } else if (Array.isArray(value)) {
      updatedData = [...value, { dateLastUpdate: new Date().toISOString() }];
    } else {
      updatedData = {
        ...value,
        dateLastUpdate: new Date().toISOString()
      };
    }

    localStorage.setItem(id, JSON.stringify(updatedData));

    return {
      [id]: updatedData
    };
  } catch (e) {
    console.error('Ошибка при сохранении в localStorage:', e);
  }
}

export function setDashboardsInLocalStorage(data, widgetsIds) {
  const dateLastUpdate = new Date().toISOString();
  try {
    widgetsIds.forEach(id => {
      const value = data[id];
      let updatedData;

      if (typeof value !== 'object' || value === null) {
        updatedData = {
          value: value || null,
          dateLastUpdate
        };
      } else if (Array.isArray(value)) {
        updatedData = [...value, { dateLastUpdate }];
      } else {
        updatedData = {
          ...value,
          dateLastUpdate
        };
      }

      localStorage.setItem(id, JSON.stringify(updatedData));
    });

    return Object.entries(data).reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        acc[key] = [...value, { dateLastUpdate }];
      } else if (typeof value === 'object' && value !== null) {
        acc[key] = { ...value, dateLastUpdate };
      } else {
        acc[key] = {
          value: value || null,
          dateLastUpdate
        };
      }

      return acc;
    }, {});
  } catch (e) {
    console.error('Ошибка при сохранении в localStorage:', e);
  }
}
