import api from 'core/api';
import { isEmpty, get, isEqual } from 'lodash';
import decamelize from 'decamelize-keys-deep';
import * as actions from './reducer';
import { useSelector } from 'react-redux';
import {
  deleteDashboardInLocalStorage,
  getDashboardsInLocalStorage,
  setDashboardInLocalStorage,
  setDashboardsInLocalStorage
} from '../../../../core/loacalStorage/operations';
import { getChecklistsDefinitionsByIds } from '../../../selectors/checklists';

const WidgetsV3Excetions = [
  'table_user_field_analytics',
  'table_checklist_items_by_operators_multi'
];

export const getDashboardAnalytics = ({ id, activeDashboard }) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const stateDashboardsRows = state.dashboardRowsResource.byIds;
      const widgetsIds = [];
      activeDashboard.rowsIds.forEach(item => {
        const dashboardRow = stateDashboardsRows[item];
        widgetsIds.push(...dashboardRow.widgetsIds);
      });

      // Сброс кэширования по виджетам раз в 2 часа
      const DashboardsRestarted = localStorage.getItem('DashboardsRestarted');
      if (DashboardsRestarted === null || (Date.now() - DashboardsRestarted) >= 7200000) {
        widgetsIds.forEach(id => deleteDashboardInLocalStorage(id));
        localStorage.setItem('DashboardsRestarted', Date.now());
      }
      const responseOnLocalStorage = getDashboardsInLocalStorage(widgetsIds);
      let response = [];
      if (isEmpty(responseOnLocalStorage)) {
        response = await api.getDashboardAnalytics({ dashboard_id: id }); // /api/v3/dashboard_proxy
        response = await setDashboardsInLocalStorage(response.body, widgetsIds);
      } else {
        response = responseOnLocalStorage;
      }
      return response.hasOwnProperty('body') ? response.body : response;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
};

export const getDashboardWidgetAnalytics = ({ ...props }) => {
  return async dispatch => {
    try {
      await dispatch(actions.loadDashboardWidgetAnalyticsStarted({ id: props.id }));
      let response = null;

      if (WidgetsV3Excetions.includes(props?.type)) {
        response = await api.getDashboardWidgetAnalyticsV3(decamelize({ widget_id: props.id }));
      } else {
        response = await api.getDashboardWidgetAnalytics({ id: props.id });
      }
      const widgetAnalyticsObject = setDashboardInLocalStorage(props.id, response.body);
      await dispatch(actions.loadDashboardWidgetAnalyticsSucceed(widgetAnalyticsObject));
      return widgetAnalyticsObject;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
};
