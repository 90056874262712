import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, message } from 'antd';
import styled from 'styled-components';
import { isEmpty, debounce } from 'lodash';
import Text from 'antd/lib/typography/Text';
import decamelizeKeysDeep from 'decamelize-keys-deep';
import { userCustomFieldsResource } from '../redux/resources/userCustomFields';
import { updateUiWidgetFilters } from '../redux/ui/widgetSettingsModal/reducer';
import CustomFieldValue from './CustomFieldsValue';
import { StyledSelect } from './WidgetUserFields';
import { selectSearch } from '../core/utils/selectSearch';

const FilterContainer = styled.div`
  width: 100%;
  margin: 8px 0;
`;

const WidgetUserFieldsValues = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { filters } = useSelector(state => state.uiWidgetSettingsModal);
  const customFields = useSelector(state => Object.values(state.userCustomFieldsResource.byIds));
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );

  useEffect(() => {
    if (isEmpty(customFields)) {
      dispatch(
        userCustomFieldsResource.operations.load({
          organization_id: organizationId,
          pagination: false,
          sort: '-created_at'
        })
      );
    }

    if (filters?.userFieldsFilter) {
      form.setFieldsValue(decamelizeKeysDeep(filters.userFieldsFilterValues));
    }
  }, []);

  const onValuesChange = useCallback(
    debounce(values => {
      dispatch(updateUiWidgetFilters({ userFieldsFilterValues: values }));
    }, 500),
    []
  );

  return (
    <Col span={24}>
      <Text strong>Фильтр по кастомным полям</Text>
      <Form
        form={form}
        onValuesChange={(changedValues, allValues) => {
          onValuesChange(allValues);
        }}
      >
        {filters?.userFieldsFilter?.map(fieldId => {
          const field = customFields.find(f => f.id === fieldId);
          if (!field) return null;
          return (
            <Form.Item key={field.id} name={field.key} style={{ marginBottom: 8 }}>
              <CustomFieldValue fieldName={field.name} fieldType={field.fieldType} />
            </Form.Item>
          );
        })}

        <FilterContainer>
          <StyledSelect
            mode="multiple"
            allowClear
            optionLabelProp="label"
            placeholder="Фильтрация по кастомным полям"
            defaultValue={filters?.userFieldsFilter || []}
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys => `Полей: ${selectedKeys.length}`}
            onChange={userFieldsFilter => {
              if (userFieldsFilter?.length <= 10) {
                dispatch(updateUiWidgetFilters({ userFieldsFilter }));
              } else {
                message.info('Можно выбрать не более 10 пользовательских полей');
              }
            }}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {customFields.map(item => (
              <StyledSelect.Option value={item.id} key={item.id} label={item.name}>
                {item.name}
              </StyledSelect.Option>
            ))}
          </StyledSelect>
        </FilterContainer>
      </Form>
    </Col>
  );
};

export default WidgetUserFieldsValues;
