import { Button, Col, Form, Input, message, Modal, Row, Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import SButton from 'components/Standard/SButton';
import { CUSTOM_FIELDS_TYPES } from 'core/utils/constants';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import { useDispatch } from 'react-redux';
import AiIcon from '../../../assets/images/ai-icon.svg';
import { userCustomFieldsResource } from '../../../redux/resources/userCustomFields';

const { Option } = Select;

const UserCustomFieldEditor = ({
  customField,
  editingId,
  addingId,
  onDelete,
  onSubmit,
  integration = null,
  setEditingCustomField
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const isActiveCustomField =
    customField?.usedForAi === true ||
    customField?.usedForFilters === true ||
    customField?.usedForReviews === true;

  const active = customField.id === editingId || customField.id === addingId;
  const [stringUrl, setStringUrl] = useState(false);

  const handleDelete = () => {
    onDelete(customField);
  };

  const handleSubmit = (values = {}) => {
    onSubmit({ ...customField, ...values });
  };

  const customFieldsOptions = Object.values(CUSTOM_FIELDS_TYPES).filter(
    type => type !== CUSTOM_FIELDS_TYPES.SELECT && type !== CUSTOM_FIELDS_TYPES.ENUM
  );

  useEffect(() => {
    if (customField?.fieldType === CUSTOM_FIELDS_TYPES.STRING_URL) {
      setStringUrl(true);
      return form.setFieldsValue({ ...customField, usedForFilters: false });
    }
    form.setFieldsValue(customField);
  }, [active]);

  const onValuesChange = (changedProp, allProps) => {
    if (allProps?.fieldType === CUSTOM_FIELDS_TYPES.STRING_URL) {
      setStringUrl(true);
      return form.setFieldsValue({ ...allProps, usedForFilters: false });
    }
    if (allProps?.fieldType !== CUSTOM_FIELDS_TYPES.STRING_URL) {
      setStringUrl(false);
    }
  };

  const deactivateCustomField = async activeStatus => {
    const result = await dispatch(
      userCustomFieldsResource.operations.updateById({
        id: customField.id,
        actions: {
          update_name: customField.name,
          update_key: customField.key,
          update_type: customField.fieldType,
          update_used_for_reviews: activeStatus,
          update_used_for_ai: activeStatus,
          update_used_for_filters: activeStatus
        }
      })
    );

    if (result) {
      message.success(isActiveCustomField ? 'Поле деактивировано' : 'Поле активировано');
      setEditingCustomField(customField);
      setEditingCustomField(null);
      Modal.destroyAll();
    }
  };

  const handleDeactivate = () => {
    if (!isActiveCustomField) {
      return deactivateCustomField(true);
    }
    return Modal.confirm({
      title: `Деактивация поля (${customField?.name})`,
      okButtonProps: { style: { display: 'none' } },
      cancelButtonProps: { style: { display: 'none' } },
      content: (
        <div>
          <p className="ant-modal-confirm-content">Вы уверены что хотите деактивировать поле?</p>
          <div
            className="ant-modal-confirm-btns"
            style={{ flexWrap: 'nowrap', display: 'flex', alignItems: 'center' }}
          >
            <Button type="default" onClick={() => Modal.destroyAll()}>
              {t('components.checklistManager.questionDescriptionModal.modal.cancel')}
            </Button>
            <Button type="primary" onClick={() => deactivateCustomField(false)}>
              Ок
            </Button>
          </div>
        </div>
      )
    });
  };

  return (
    <Col span={24}>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={customField}
        onValuesChange={onValuesChange}
      >
        <Row justify="space-between">
          <Col span={18}>
            <Row>
              {integration && (
                <Col span={24}>
                  <Row>
                    <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{integration}</Text>
                  </Row>
                </Col>
              )}
              <Col span={24}>
                <Row gutter={[24, 4]}>
                  <Col>
                    <Form.Item
                      label={t(
                        'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.fieldName'
                      )}
                      rules={[{ required: !isEmpty(customField.name) }]}
                      name="name"
                      style={{ marginBottom: '4px' }}
                    >
                      <Input
                        placeholder={t(
                          'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.fieldName'
                        )}
                        disabled={!active}
                      />
                    </Form.Item>
                  </Col>

                  <Col>
                    <Form.Item
                      label={t(
                        'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.fieldKey'
                      )}
                      rules={[{ required: true }]}
                      name="key"
                      style={{ marginBottom: '4px' }}
                    >
                      <Input placeholder="sample_key" disabled={!active} />
                    </Form.Item>
                  </Col>

                  <Col>
                    <Form.Item
                      label={t(
                        'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.fieldType'
                      )}
                      rules={[{ required: true }]}
                      name="fieldType"
                      style={{ marginBottom: '4px' }}
                    >
                      <Select
                        style={{ width: '244px' }}
                        placeholder={t(
                          'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.fieldType'
                        )}
                        disabled={!active}
                      >
                        {customFieldsOptions.map(type => (
                          <Option value={type} key={type}>
                            {type}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[24, 0]}>
                  <Col>
                    <Form.Item
                      name="usedForAi"
                      valuePropName="checked"
                      style={{ marginBottom: '4px' }}
                    >
                      <Checkbox disabled={!active}>
                        {t(
                          'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.usedForAI'
                        )}
                        <img
                          src={AiIcon}
                          alt="ai-icon"
                          style={{ marginLeft: '5px', paddingBottom: '2px' }}
                        />
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="usedForReviews"
                      valuePropName="checked"
                      style={{ marginBottom: '4px' }}
                    >
                      <Checkbox disabled={!active}>
                        {t(
                          'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.visibleForAI'
                        )}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="usedForFilters"
                      valuePropName="checked"
                      style={{ marginBottom: '4px' }}
                    >
                      <Checkbox disabled={!active}>
                        {t(
                          'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.usedForFilters'
                        )}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={6}>
            <Row
              justify="end"
              style={{
                marginTop: '32px',
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'column'
              }}
            >
              <Col>
                {active || isEmpty(addingId || editingId) ? (
                  <SButton type="link" htmlType="submit" loading={customField?.loading}>
                    {active
                      ? t(
                          'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.save'
                        )
                      : t(
                          'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.edit'
                        )}
                  </SButton>
                ) : null}
              </Col>
              {active ||
                (isEmpty(addingId || editingId) && (
                  <Col>
                    <SButton
                      type="link"
                      style={{ color: 'var(--blue_2)' }}
                      onClick={handleDeactivate}
                      disabled={customField?.loading}
                    >
                      {isActiveCustomField
                        ? t(
                            'integrationsSettingsPage.integrationPage.customFieldsSettings.editor.deactivate'
                          )
                        : 'Активировать'}
                    </SButton>
                  </Col>
                ))}
              <Col>
                <SButton
                  type="link"
                  style={{ color: 'var(--blue_2)' }}
                  onClick={handleDelete}
                  disabled={customField?.loading}
                >
                  {t('integrationsSettingsPage.integrationPage.customFieldsSettings.editor.delete')}
                </SButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Col>
  );
};

export default UserCustomFieldEditor;
