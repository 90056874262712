export default {
  dashboardPage: {
    widget: {
      filtersIsNotInstalled: 'No se han elejido los filtros',
      unitName: 'Departamento',
      needToInstallFilters: 'Es necesario elegir los filtros',
      checkFilters: 'Revise los filtros',
      emptyFiltersData: 'Por estos filtros no se han encontrado datos',
      emptyName: 'Nombre no establecido',
      withoutGroups: 'Sin grupo',
      outdated: {
        title: 'Widget no compatible',
        outdatedFlagsAndCommentsWidgets:
          'Para seguir trabajando, cree el widget XL "Banderas y comentarios"'
      },
      conflicts: {
        title: 'Los ajustes establecidos no están disponibles',
        description1:
          'Debido a un cambio en la configuración del perfil, has perdido el acceso a los datos.',
        description2:
          'Para cambiar la configuración del widget, pulse "Borrar widget" y establezca nuevos filtros. Para recuperar el acceso a los ajustes previamente establecidos, póngase en contacto con el administrador.'
      },
      buttons: {
        more: ' Detalladamente',
        export: 'Exportar a Exel'
      },
      messages: {
        analyticsSuccessfullyExported: 'Analítica exportada',
        exportAnalyticsFailed: 'No se logró exportar la analítica',
        needMoreData: 'Para crear un gráfico, necesita estimaciones de al menos dos días.'
      },
      utils: {
        emptyName: 'El nombre no se ha encontrado',
        nameIsNotInstalled: 'Nombre no establecido:',
        emptyDescription: 'No se ha encontrado la descripción',
        allTime: 'Por todo el tiempo',
        info: 'Información'
      },
      modal: {
        tooltip: 'Ajustes',
        title: 'Ajustes del widgest',
        buttons: {
          reset: 'Restablecer widget',
          cancel: 'Cancelar',
          save: 'Guardar'
        },
        messages: {
          widgetSuccessfullyUpdated: 'Widgest actualizado',
          updateWidgetFailed: 'No se logró actualizar el wifgest',
          requiredField: '(Campo obligatorio)'
        },
        form: {
          type: 'Tipos de datos',
          typePlaceholder: 'Elija los datos que serán reflejados',
          name: 'Nombre del widgest',
          namePlaceholder: 'Introduzca el nombre del widgest',
          communicationDate: 'Fecha de la cominucación',
          communicationsCreationPeriod: 'Período de creación de la comunicación',
          communicationsCreationPeriodPlaceholder: 'Elija el mes',
          isWeekendView: 'Señalar los fines de semana',
          isWeekendViewInfo:
            'Le permite elegir si las columnas para los fines de semana incluidos en el período especificado se' +
            ' mostrarán en el widget y si se tendrán en cuenta al calcular la columna "Total".',
          isWeekendViewPlaceholder: 'Elejia el estado de visualización de los fines de semana',
          reviewDate: 'Fecha de evaluación',
          date: 'Fecha',
          unit: 'Departamento',
          departmentBeingAudited: 'Departamento auditado',
          departmentBeingAuditedPlaceholder: 'Selecciona el departamento a revisar',
          unitPlaceholder: 'Elija un departamento',
          operators: 'Operadores',
          operatorsPlaceHolder: 'Seleccionar operadores',
          reviewers: 'Supervisores',
          reviewersPlaceHolder: 'Elija los supoervisores',
          checklistDefinition: 'Formulario de evaluación ',
          additionalChecklistDefinition: 'Formularios de evaluación adicionales',
          taskDefinitions: 'Etiquetas',
          taskDefinitionsPlaceHolder: 'Selecciona etiquetas',
          isReviewed: 'Estatus de la evaluación',
          isReviewedPlaceHolder: 'Elija el estado de la evaluación',
          direction: 'Dirección de la llamada',
          directionPlaceHolder: 'Elija la direción de la llamada',
          dateFormat: 'Desplegar formato',
          dateFormatPlaceHolder: 'Seleccionar formato de visualización'
        }
      }
    },
    dashboard: {
      emptyDashboard: 'No se ha creado ningún panel',
      emptyDashboardRows: 'No se ha creado ninguna línea',
      newDashboard: 'Panel nuevo',
      loading: 'Cargando análisis',
      messages: {
        dashboardSuccessfullyCreated: 'Panel creado',
        createDashboardFailed: 'No se logró crear el panel'
      },
      buttons: { create: 'Generar' },
      reviewsCountShort: 'Evaluaciones',
      drawer: {
        title: 'Ajustes del panel',
        dashboards: {
          title: 'Paneles',
          confirmDelete: {
            title: '¿Está seguro que quiere eliminar este panel?',
            description: 'Luego de eliminar el panel no podrá recuperalo'
          },
          messages: {
            rowsLimit: 'Un panel no puede tener más de 10 líneas',
            dashboardSuccessfullyDeleted: 'Panel eliminado',
            dashboardDeleteFailed: 'No se logró eliminar el panel',
            dashboardSuccessfullyCopied: 'Panel copiado',
            copyDashboardFailed: 'No se logró copiar el panel',
            getPro: 'Pasar a otra tarifa para crear mñas paneles. '
          },
          menu: {
            edit: 'Cambiar el nombre del panel',
            copy: 'Copiar el panel',
            delete: 'Eliminar el panel'
          },
          buttons: {
            more: 'Detalladamente',
            addDashboard: 'Agregar un panel nuevo'
          }
        },
        rows: {
          title: 'Paneles',
          confirmDelete: {
            title: '¿Está seguro que quiere eliminar la línea?',
            ok: 'Si',
            cancel: 'No'
          },
          buttons: { addRow: 'Añadir una línea' },
          rowsCount1: 'Línea con 1 panel',
          rowsCountPT1: 'Línea con ',
          rowsCountPT2: 'paneles'
        }
      }
    },
    tableReviewsCountByOperators: {
      table: {
        columns: {
          dates: 'Fechas',
          totalScore: 'Resultado',
          reviewsCount: 'Cantidad de evaluaciones',
          averageScore: 'Media por administrador'
        }
      },
      viewModes: {
        types: {
          table: { name: 'Tabla' },
          chart: {
            name: 'Calendario',
            seriesNames: {
              averageScoreByOperator: 'Resultado medio por empleado',
              totalAverageScore: 'Resultado medio de todos los empleados'
            },
            yaxis: 'Evaluación media',
            scoreStr: 'puncte',
            percantageStr: '%',
            table: {
              name: 'Nombre',
              description: 'Descripción',
              averageScoreStr: 'Puntuación media (máx:'
            }
          }
        }
      }
    },
    tableChecklistItemsByOperators: {
      table: {
        columns: {
          questions: 'Criterios',
          groups: 'Grupos',
          totalScore: 'Salir',
          average: 'Promedio'
        }
      },
      viewModes: {
        questions: 'Criterios',
        groups: 'Grupos'
      }
    },
    tableChecklistItemsHistory: {
      table: {
        columns: {
          dates: 'Fecha',
          totalScore: 'Salir',
          reviewsCount: 'Numero de cheques',
          average: 'Promedio'
        }
      },
      viewModes: {
        questions: 'Criterios',
        groups: 'Grupos'
      }
    },
    flagsAndComments: {
      viewModes: {
        operators: 'Operadores',
        units: 'Departamentos'
      },
      table: {
        columns: {
          name: 'Nombre',
          commentsCount: 'Número de banderas y comentarios'
        }
      }
    },
    tableChecklistsScoresByOperators: {
      firstColumnNames: {
        operators: 'Empleados',
        units: 'Departamentos'
      },
      columns: { totalScore: 'Salir' },
      rows: { footer: 'La media' }
    },
    tableReviewsCountByReviewers: {
      changeViewType: 'Cambiar el modo de vista',
      firstColumnNames: {
        reviewers: 'Supervisores',
        units: 'Departamentos'
      },
      rows: {
        header: 'Tiempo total para verificaciones de llamadas',
        headerInfo:
          'La duración total de las llamadas verificadas. Tiene en cuenta la duración de las llamadas verificadas tantas veces como fueron verificadas.',
        footer: 'Número total de cheques'
      },
      viewModes: {
        chart: {
          table: {
            columns: {
              name: 'Nombre',
              reviewsCount: 'Evaluaciones',
              reviewsPercentage: '% evaluaciones',
              totalReviewsTime: 'Tiempo'
            },
            rows: { total: 'Hora' }
          },
          chart: {
            series: {
              reviewed: 'Verificado por empleado',
              totalReviewed: 'Total de contactos verificados'
            },
            yAxis: 'Cantidad de evaluaciones'
          }
        }
      }
    },
    tableReviewsWithScores: {
      table: {
        columns: {
          type: 'Un tipo',
          operatorId: 'Operador',
          communicationKey: 'Número de teléfono / ID de comunicación',
          totalScore: 'Salir',
          clientInteractionStartedAt: 'Fecha de comunicación',
          reviewCreatedAt: 'Fecha de evaluación'
        }
      },
      exportError:
        'Está intentando descargar más de 5000 evaluaciones. Acortar el período para reducir el número de evaluaciones exportadas'
    },
    widgetMeta: {
      longSortMessage: 'La clasificación por criterios puede llevar mucho tiempo.',
      maxItemsCount: 'El número máximo de comprobaciones no puede superar los 1000.',
      foundReviews: 'Cheques encontrados: '
    },
    tableUserFieldAnalytics: {
      changeViewType: 'Cambiar tipo de vista',
      columnsNames: {
        operatorId: 'Operador',
        communicationCount: 'Total',
        responsesCount: 'Con campo',
        fractionCount: 'Fracción',
        totalScore: 'Total'
      },
      columnsTooltips: {
        operatorId: 'Operador',
        communicationCount: 'Total de comunicaciones del operador durante el período seleccionado',
        responsesCount:
          'Número de comunicaciones que contienen los campos personalizados seleccionados para el período indicado',
        fractionCount:
          'Fracción de las comunicaciones con campos personalizados respecto al número total de comunicaciones durante el período seleccionado',
        fraction:
          'Fracción del valor del campo personalizado respecto al número total de todos los valores de los campos personalizados seleccionados para este operador durante el período indicado'
      },
      rows: {
        total: 'Total'
      }
    }
  }
};
